import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import exchange from "../Images/exchange.webp";
import wallet from "../Images/wallet.webp";
import lending from "../Images/lending.webp";
import token from "../Images/token.webp";
import app from "../Images/mobile-development.webp";
import smartcontract from "../Images/smart-contract.webp";
import stake from "../Images/stake.webp";
import farm from "../Images/bitcoin-mining.webp";
import data from "./blockchain.json";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Leading DeFi Development Company - Block Intelligence </title>
        <meta
          property="title"
          content="Leading DeFi Development Company - Block Intelligence"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/defi-development-company"
        />

        <meta
          name="description"
          content="Discover Block Intelligence, a top DeFi Development Company, specializing in creating transformative solutions for decentralized finance. Explore our DeFi services and innovative approaches today."
        />
        <meta
          name="keywords"
          content="DeFi development company, DeFi development Services, DeFi Development Solution, Decentralized finance development, DeFi development, DeFi Exchange Platform Development, DeFi Lending Platform Development, DeFi Smart Contract Development, DeFi Clone Script, Benefits of DeFi development, Features of DeFi development, Decentralized Finance Development Service."
        />
      </Helmet>
      <Header />
      <Contactbar />
      <section className="Defi">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 banner_con simple-video mb-sx-2">
              <h1 className="welcome wow fadeInUp h1tag">DeFi Development Company</h1>
              <p className="wow fadeInUp">
                At Block Intelligence, we are the architects of the
                decentralized financial revolution, crafting innovative DeFi
                solutions that redefine the future of finance.
              </p>
              <div className="justify-content-center mt-5">
                <a href="#contact" className="button">
                  Get Demo
                </a>
              </div>
            </div>
            <div className="col-lg-6 mx-auto mt-4 d-flex justify-content-center align-items-center">
              <div
                className="wow zoomIn"
                data-wow-duration="2s"
                data-wow-offset="100"
              >
                <div
                  className="banner_i"
                  data-wow-delay="0.2s"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                >
                  <img
                    src={require("../Images/defi.webp")}
                    alt="DeFi Exchange Platform Development"
                    className="defi-banner d-block mx-auto img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatdefi ">
        <div className="container ">
          <div className="whatdefi ">
            <div className="row">
              <div className="col-lg-6 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/whatdefi.webp")}
                  className="whatimg"
                  alt="Defi"
                />
              </div>
              <div className="col-lg-6">
                <h2>What is Defi?</h2>
                <p>
                  {" "}
                  DeFi, short for Decentralized Finance, is the epitome of
                  financial freedom. It's a financial system that's open,
                  borderless, and accessible to anyone with an internet
                  connection. DeFi eliminates intermediaries, putting you in
                  control of your assets and investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="usecases">
        <div className="container">
          <h2>Our deveopment services</h2>

          <div className="row servicess">
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card">
                <div className="usecard-img-tops">
                  <img
                    src={exchange}
                    alt="DeFi Exchange Platform Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title">
                    DeFi Exchange Platform Development
                  </h5>
                  <p>
                    {" "}
                    DeFi Exchange Platform Development Transform your crypto
                    trading experience with our DeFi exchange platforms,
                    offering liquidity, security, and a seamless user interface.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-3">
              <div className="card usecase-card1">
                <div className="usecard-img-tops">
                  <img
                    src={lending}
                    alt="DeFi Lending Platform Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title text-dark">
                    {" "}
                    DeFi Lending Platform Development
                  </h5>
                  <p className="text-dark">
                    {" "}
                    Allow users to lend, borrow, and earn with our DeFi lending
                    platforms, driving financial inclusivity through
                    decentralized finance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card">
                <div className="usecard-img-tops">
                  <img
                    src={token}
                    alt="DeFi Token Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title">DeFi Token Development</h5>
                  <p>
                    {" "}
                    Create your unique DeFi tokens with our expert token
                    development services, enabling you to fuel DeFi ecosystems
                    or launch your own projects
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card1">
                <div className="usecard-img-tops">
                  <img
                    src={wallet}
                    alt="DeFi Wallet Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title text-dark">
                    {" "}
                    DeFi Wallet Development{" "}
                  </h5>
                  <p className="text-dark">
                    Securely manage your DeFi assets with user-friendly DeFi
                    wallets, ensuring easy access and complete control over your
                    digital finances.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row servicess">
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card1">
                <div className="usecard-img-tops">
                  <img
                    src={app}
                    alt="DeFi DApp Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title text-dark">DeFi Dapp Development</h5>
                  <p className="text-dark">
                    {" "}
                    Bring your decentralized application ideas to life with our
                    DeFi Dapp development services, leveraging blockchain
                    technology for innovative solutions.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-3">
              <div className="card usecase-card">
                <div className="usecard-img-tops">
                  <img
                    src={smartcontract}
                    alt="DeFi Smart Contract Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title">DeFi Smart Contract Development</h5>
                  <p>
                    {" "}
                    Build trust in your DeFi projects with robust, audited smart
                    contracts, ensuring secure and transparent transactions on
                    the blockchain.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card1">
                <div className="usecard-img-tops">
                  <img
                    src={stake}
                    alt="DeFi Staking Platform Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle ">
                  <h5 className="use-title text-dark">
                    DeFi Staking Platform Development
                  </h5>
                  <p className="text-dark">
                    {" "}
                    Engage users with rewarding staking experiences, allowing
                    them to participate and earn within your DeFi ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card">
                <div className="usecard-img-tops">
                  <img
                    src={farm}
                    alt="Farming Platform Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title">
                    DeFi Yield Farming Platform Development{" "}
                  </h5>
                  <p>
                    {" "}
                    Harness the power of yield farming with our DeFi platforms,
                    enabling users to maximize returns by providing liquidity
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="devservices">
        <div className="container">
          <div className="dev head">
            <h2>DeFi's Transformative Potential Across Industries</h2>
          </div>

          <div className="row ">
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/bank.webp")}
                    alt="Finance and Banking"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Finance and Banking</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/bitcoin.webp")}
                    alt="Cryptocurrency"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Cryptocurrency</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/real-estate.webp")}
                    alt="Real Estate"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Real Estate</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/supply-chain.webp")}
                    alt="Supply Chain"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Supply Chain</h5>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/patient.webp")}
                    alt="Healthcare"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Healthcare</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/insurance.webp")}
                    alt="Insurance"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Insurance</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/market.webp")}
                    alt="Agriculture"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Agriculture</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/creativity.webp")}
                    alt="Art and Collectibles"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Art and Collectibles</h5>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/joystick.webp")}
                    alt="Gaming"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Gaming</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/learning.webp")}
                    alt="Education"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Education</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/travel (1).webp")}
                    alt="Hospitality & Travel"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Hospitality and Travel</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6   mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/question.webp")}
                    alt="DeFi Exchange Platform Development"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Legal Services</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="diftable">
        <div className="container">
          <div className="difheading text-center">
            <h2 className="dif my-3">CEFI Vs DEFI</h2>
          </div>
          <div
            className="table-responsive tborder table-scroll wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">Category</th>
                  <th className="text-center">DeFi</th>
                  <th className="text-center">CeFi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="firstdata">Control and Ownership</td>
                  <td className="secdata">
                    Users have full control and ownership of their assets and
                    private keys, reducing reliance on intermediaries like
                    banks.
                  </td>
                  <td className="thirddata">
                    Centralized financial institutions hold custody of user
                    assets, requiring trust in third parties.
                  </td>
                </tr>
                <tr>
                  <td className="firstdata">Access and Inclusivity</td>
                  <td className="secdata">
                    Accessible to anyone with an internet connection, fostering
                    financial inclusivity on a global scale.
                  </td>
                  <td className="thirddata">
                    Access typically requires a bank account or other
                    centralized financial services, limiting accessibility.
                  </td>
                </tr>
                <tr>
                  <td className="firstdata">Transparency and Trust</td>
                  <td className="secdata">
                    Transactions and smart contracts are transparent and
                    verifiable on the blockchain, enhancing trust.
                  </td>
                  <td className="thirddata">
                    Operations are often opaque, with users needing to trust
                    centralized authorities and systems.
                  </td>
                </tr>
                <tr>
                  <td className="firstdata">Speed and Efficiency</td>
                  <td className="secdata">
                    Transactions are processed quickly, often in minutes or
                    seconds, without the need for intermediaries.
                  </td>
                  <td className="thirddata">
                    Traditional financial transactions can be slower, involving
                    multiple intermediaries and settlement times.
                  </td>
                </tr>
                <tr>
                  <td className="firstdata">
                    Liquidity and Yield Opportunities
                  </td>
                  <td className="secdata">
                    Users can participate in liquidity provision, yield farming,
                    and lending, potentially earning higher returns.
                  </td>
                  <td className="thirddata">
                    Traditional savings accounts and investments may offer lower
                    returns and limited liquidity options.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className="benfits-defi">
        <div className="container">
          <div className="key-feature-cex-content">
            <h2 className="h2-mobile h2-md">
              Benefits of Our DeFi Development Services
            </h2>
          </div>
          <div className="key-feature-cex-card-row">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/secure-payment.webp")}
                    className="card-img"
                    alt="Security"
                  />
                  <p className="key-feature-cex-card-title">Security</p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Our battle-tested smart contracts are fortress-like,
                    ensuring your assets are always safe.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/transparency.webp")}
                    className="card-img"
                    alt="Transparency"
                  />
                  <p className="key-feature-cex-card-title"> Transparency</p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    With blockchain at its core, DeFi is inherently transparent.
                    No hidden fees or agendas.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/world.webp")}
                    className="card-img"
                    alt="Global Research"
                  />
                  <p className="key-feature-cex-card-title">Global Reach </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    DeFi is accessible to anyone, anywhere, making financial
                    inclusivity a reality.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/profits.webp")}
                    className="card-img"
                    alt="Profit"
                  />
                  <p className="key-feature-cex-card-title"> High Returns </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Yield farming, liquidity provision, and staking - DeFi
                    offers opportunities for impressive returns.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" Finance-Development">
        <div className="container">
          <h2> Blockchain We Work on for Decentralized Finance Development </h2>
          <div className="row mt-5 justify-content-center">
            {data.map((screenshot, index) => (
              <div className="col-lg-1 col-md-2 col-sm-3">
                <div className="finance-card shadow">
                  <div className="card-img-fiance">
                    <img
                      src={`https://transfer.swft.pro/swft-v3/images/coins/${screenshot.chain}.png`}
                      alt={screenshot.name}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="featuretext mt-3 ">
                  <h5 className="card-title">{screenshot.name}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="sectionss">
        <div className="container">
          <h2 className="h2">
            Process of Our Decentralized Finance Development Service
          </h2>

          <div className="row mt-5">
            <div className="col-lg-6 pleft fade-up">
              <div className="d-flex flex-column align-items-end">
                <div class="cloud-container">
                  <img
                    src={require("../Images/idea.webp")}
                    className="process-img img-fluid"
                    alt="DeFi Development Solution"
                  />
                  /
                </div>
              </div>
            </div>

            <div className="col-lg-6 pright fade-up">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3">DeFi Development Solution</h3>
                <p className="processpara mb-0">
                  We collaborate with you to understand your vision and goal
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5 processeven">
            <div class="col-lg-6 pright fade-down order-md-1">
              <div class="align-items-center justify-content-start">
                <h3 class="processhead mb-3 mt-5">Design & Development</h3>
                <p class="processpara mb-0">
                  Our experts craft smart contracts, UI/UX, and backend systems.
                </p>
              </div>
            </div>
            <div class="col-lg-6 pleft fade-down order-md-2">
              <div class="d-flex flex-column align-items-start">
                <div class="cloud-container">
                  <img
                    src={require("../Images/html-code.webp")}
                    class="process-img img-fluid"
                    alt="Design & Development"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6 pleft fade-up mt-5">
              <div className="d-flex flex-column align-items-end">
                <div class="cloud-container">
                  <img
                    src={require("../Images/testing.webp")}
                    className="process-img img-fluid"
                    alt="Testing & Auditing"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pright fade-up">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3 mt-5">Testing & Auditing</h3>
                <p className="processpara mb-0">
                  Rigorous testing and third-party audits ensure rock-solid
                  security.
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5 processeven">
            <div className="col-lg-6 pright fade-down">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3 mt-5">Deployment</h3>
                <p className="processpara mb-0">
                  We launch your DeFi platform into the blockchain ecosystem.
                </p>
              </div>
            </div>
            <div className="col-lg-6 pleft fade-down">
              <div className="d-flex flex-column align-items-start">
                <div class="cloud-container">
                  <img
                    src={require("../Images/cloud.webp")}
                    className="process-img img-fluid"
                    alt="Deployment"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5 ">
            <div className="col-lg-6 pleft fade-up">
              <div className="d-flex flex-column align-items-end">
                <div class="cloud-container">
                  <img
                    src={require("../Images/web-maintenance.webp")}
                    className="process-img img-fluid"
                    alt="Maintenance & Support"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pright fade-up">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3 mt-5">Maintenance & Support</h3>
                <p className="processpara mb-0">
                  We're with you post-launch, providing maintenance and support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-defi">
        <div className="container">
          <div className="feature-content pb-3">
            <h2>Features of Our DeFi Service</h2>
          </div>
          <div className="feature-card-row">
            <div className="row justify">
              <div className="col-lg-4 col-md-4">
                <div className="feature-card">
                  <img
                    src={require("../Images/smart-contract2.webp")}
                    className="feature-img"
                    alt="Smart Contracts"
                  />
                  <p className="feature-card-title">Robust Smart Contracts</p>
                  <p className="feature-card-para">
                    Our robust smart contracts are the backbone of your DeFi
                    project, ensuring secure, tamper-proof, and trustless
                    transactions.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="feature-card">
                  <img
                    src={require("../Images/cross-platform.webp")}
                    className="feature-img"
                    alt="Cross-Platform"
                  />
                  <p className="feature-card-title">
                    Cross-Platform Compatibility
                  </p>
                  <p className="feature-card-para">
                    Enjoy the flexibility of cross-platform compatibility, as
                    our DeFi solutions seamlessly integrate with various
                    blockchain networks and devices.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="feature-card">
                  <img
                    src={require("../Images/scale.webp")}
                    className="feature-img"
                    alt="Scalability for High Traffic"
                  />
                  <p className="feature-card-title">
                    Scalability for High Traffic
                  </p>
                  <p className="feature-card-para">
                    Our DeFi solutions are built to handle high traffic volumes,
                    ensuring your platform remains efficient and responsive even
                    during peak usage.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="feature-card-row mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-4">
                <div className="feature-card">
                  <img
                    src={require("../Images/interface.webp")}
                    className="feature-img"
                    alt="User Friendly UI"
                  />
                  <p className="feature-card-title">User-Friendly Interfaces</p>
                  <p className="feature-card-para">
                    We prioritize user experience with intuitive and
                    user-friendly interfaces, making it easy for both novice and
                    experienced users to navigate your DeFi platform.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="feature-card">
                  <img
                    src={require("../Images/cash-flow.webp")}
                    className="feature-img"
                    alt="Liquidity"
                  />
                  <p className="feature-card-title">
                    Automated Liquidity Provision{" "}
                  </p>
                  <p className="feature-card-para">
                    Our DeFi solutions include automated liquidity provision,
                    ensuring a healthy and liquid ecosystem for your platform's
                    assets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whatdefi">
        <div className="container">
          <div className="whatdefi">
            <div className="row">
              <div className="col-lg-7 ">
                <h2>Why Choose Block Intelligence for DeFi Development?</h2>

                <p>
                  We Block Intelligence are the architects of financial
                  transformation in the decentralized world. We don't just build
                  DeFi platforms; we engineer financial freedom. Our team
                  combines technical prowess with a passion for innovation,
                  ensuring that your DeFi project stands out in a crowded space.
                  From crafting robust smart contracts to creating user-friendly
                  interfaces, we leave no stone unturned in making your vision a
                  reality. We are the driving force behind countless successful
                  DeFi ventures, and when you choose us, you're not just
                  choosing a development partner; you're choosing a journey to
                  redefine finance. Join us, and let's build the future of
                  finance together, one block at a time.
                </p>
              </div>
              <div className="col-lg-5 mx-auto ">
                <img
                  src={require("../Images/whyy.webp")}
                  className="whatimg chose "
                  alt="DeFi development Services"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="techstack">
        <div className="container">
          <h2 className="tech-h2">Blockchain Tech Stacks</h2>
          <div className="stack">
            <p>
              Our team of blockchain developers are experts at building
              innovative blockchain solutions on the best available blockchain
              platform.
            </p>
          </div>
          <div className="row tech">
            <div className="col-lg-12 techstacks">
              <div className="slider">
                <div className="slide-track">
                  <div className="slide">
                    <img
                      src={require("../Images/htmllogo.webp")}
                      className="bitcoin"
                      alt="html"
                    />
                    <p>Html</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/csslogo.webp")}
                      className="bitcoin"
                      alt="Css"
                    />
                    <p>Css</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/bootsrap.webp")}
                      className="bitcoin"
                      alt="Bootstrap"
                    />
                    <p>Bootstrap</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/angularlogo.webp")}
                      className="bitcoin"
                      alt="Angular"
                    />
                    <p>Angular</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/reactlogo.webp")}
                      className="bitcoin"
                      alt="React Js"
                    />
                    <p>React Js</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/nodejslogo.webp")}
                      className="bitcoin"
                      alt="Node Js"
                    />
                    <p>Node Js</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/net-logo.webp")}
                      className="bitcoin"
                      alt="Dot Net"
                    />
                    <p>Dot Net</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/phplogo.webp")}
                      className="bitcoin"
                      alt="Php"
                    />
                    <p>Php</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Python-Logo.webp")}
                      className="bitcoin"
                      alt="Python"
                    />
                    <p>Python</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/flutter-logo.webp")}
                      className="bitcoin"
                      alt="Flutter"
                    />
                    <p>Flutter</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Swift_logo.webp")}
                      className="bitcoin"
                      alt="Swift"
                    />
                    <p>Swift</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/iconic-logo.webp")}
                      className="bitcoin"
                      alt="Iconic"
                    />
                    <p>Iconic</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Mongodb-logo.webp")}
                      className="bitcoin"
                      alt="Mongo DB"
                    />
                    <p>Mongo DB</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Elastic-logo.webp")}
                      className="bitcoin"
                      alt="ElasticSearch"
                    />
                    <p>ElasticSearch</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/PostgreSQL-logo.webp")}
                      className="bitcoin"
                      alt="PostgreSQL"
                    />
                    <p>PostgreSQL</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/mysql-logo.webp")}
                      className="bitcoin"
                      alt="My SQL"
                    />
                    <p>My SQL</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/solidity-logo.webp")}
                      className="bitcoin"
                      alt="Solidity"
                    />
                    <p>Solidity</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/eth-logo.webp")}
                      className="bitcoin"
                      alt="Ethereum"
                    />
                    <p>Ethereum</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/hyper.webp")}
                      className="bitcoin"
                      alt="Hyperledger"
                    />
                    <p>Hyperledger</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/solana-logo.webp")}
                      className="bitcoin"
                      alt="Solona"
                    />
                    <p>Solona</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FillForm />
      <Footer />
    </>
  );
}
