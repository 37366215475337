import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Cryptocurrency Wallet Development Company</title>
        <meta
          property="title"
          content="Cryptocurrency Wallet Development Company
"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/crypto-wallet-development-company"
        />
        <meta
          name="description"
          content="Block Intelligence is a leading cryptocurrency wallet development company that offers secure and customized solutions for your digital assets. We can help you create and manage your own crypto wallet with ease."
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="token_head cryptowallet">
        <section className="p2p tokens ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h2 className="wow fadeInUp h1tag">
                  Cryptocurrency <span>Wallet Development</span> Company
                </h2>
                <p className="wow fadeInUp mt-4">
                  Block Intelligence is a leading cryptocurrency wallet
                  development company that offers secure and user-friendly
                  solutions for storing and managing your digital assets. We
                  have a team of expert cryptocurrency wallet developers who can
                  design and develop wallets for various platforms.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mt-5">
                <div className="">
                  <img
                    src={require("../Images/walletbanner1.webp")}
                    className="d-block mx-auto mt-5"
                    width="100%"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/walletbanner.webp")}
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2>
                    Overview of <span>Crypto Wallet</span> Development
                  </h2>
                  <p>
                    Crypto wallet development is a process of creating a
                    software or hardware device that allows users to send,
                    receive, and store cryptocurrencies, such as Bitcoin,
                    Ethereum, or Litecoin. Crypto wallet development involves
                    choosing the type and features of the wallet, such as hot or
                    cold, custodial or non-custodial, single or multi-currency,
                    etc. It also involves choosing the programming language,
                    framework, and library for the wallet, such as Java, C#,
                    React, Angular, etc. It also involves choosing the security
                    and encryption methods for the wallet, such as private keys,
                    public keys, mnemonics, biometrics, etc.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <h2 className="mb-3 text-center">
              Benefits of <span>Crypto Wallet</span> Development
            </h2>

            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <p></p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    {"  "}Security Strong encryption and authentication
                    protocols safeguard your assets.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    {"  "}Accessibility Access your funds anytime, anywhere,
                    providing unparalleled convenience.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    {"  "}Decentralization Eliminate reliance on intermediaries,
                    enabling true peer-to-peer transactions.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="p2p crypto exchange development company"
                    />
                    {"  "}User Control Users have full control over their
                    private keys, enhancing security.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="p2p crypto exchange development company"
                    />
                    {"  "}Compatibility Support for multiple cryptocurrencies
                    ensures versatility in transactions.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="p2p crypto exchange development company"
                    />
                    {"  "}Innovation Stay ahead with features like smart
                    contracts and NFT integration.
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5 pt-5">
                <img
                  src={require("../Images/cryptowallet2.png")}
                  className="mx-auto d-block"
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract py-5">
          <div className="container-fluid py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    Process of Our <span>Smart Contract</span> Audit
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    We follow a simple and streamlined process for our smart
                    contract audit service, which consists of the following
                    steps
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2 processwallet shadow">
                <h4 className="text-white text-center">Consultation</h4>
                <p className="text-center">Understand your needs and goals.</p>
              </div>
              <div className="col-lg-2 processwallet shadow">
                <h4 className="text-white text-center">Design</h4>
                <p className="text-center">
                  Develop an intuitive and secure wallet interface.
                </p>
              </div>
              <div className="col-lg-2 processwallet shadow">
                <h4 className="text-white text-center">Development</h4>
                <p className="text-center">
                  Implement advanced features with a focus on security.
                </p>
              </div>
              <div className="col-lg-2 processwallet shadow">
                <h4 className="text-white text-center">Testing</h4>
                <p className="text-center">
                  Rigorous testing to ensure functionality and security.
                </p>
              </div>
              <div className="col-lg-2 processwallet shadow">
                <h4 className="text-white text-center">Deployment</h4>
                <p className="text-center">Launch your custom crypto wallet.</p>
              </div>
              <div className="col-lg-2 processwallet shadow">
                <h4 className="text-white text-center">Maintenance</h4>
                <p className="text-center">Continuous support and updates.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="mb-3">
                    Why Choose Block Intelligence for <span>Crypto Wallet</span>{" "}
                    Development?
                  </h2>
                  <p className="">
                    Block Intelligence is the best choice for crypto wallet
                    development because
                  </p>
                  <p></p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    We have a team of expert crypto wallet developers who have
                    extensive knowledge and experience in crypto wallet
                    development, blockchain, and cryptocurrency.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    We offer customized and innovative crypto wallet development
                    solutions that can meet your specific needs and
                    expectations.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    We use the latest technologies and tools to create crypto
                    wallets that are fast, efficient, and reliable.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    We provide crypto wallet testing, maintenance, and support
                    services to ensure the quality and performance of your
                    crypto wallets.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    We offer competitive and transparent pricing and delivery
                    models that can suit your budget and timeline.
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5 pt-5">
                <img
                  src={require("../Images/wallet3.webp")}
                  className="mx-auto d-block"
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    How to Monetize with <span>Crypto Wallet</span> Development?
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    Monetizing crypto wallet development involves
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/money-transfer.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Transaction Fees</h3>
                  <p>
                    Charge a small fee for transactions conducted through your
                    wallet.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/icons/smartquality.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Premium Features</h3>
                  <p>
                    Premium feature like subscription model can be implemented
                    to avail new features
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/third-party (1).webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Token Listings</h3>
                  <p>
                    {" "}
                    Charge projects to list their tokens on your wallet for
                    increased visibility.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/icons/smarttranspanrency.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Transparency</h3>
                  <p>
                    We provide you with a detailed and comprehensive audit
                    report that contains all the information.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/icons/smartsupport.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Customization Services</h3>
                  <p>
                    Charge users for personalized wallet features or branding.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent text-center">
                  <h2>Take Away</h2>
                  <p className="text-center my-4">
                    Crypto wallet development plays a central role in navigating
                    the digital financial landscape. It allows users with
                    control, security, and accessibility while opening avenues
                    for monetization.Getting started with crypto wallet
                    development is tedious process Block intelligence
                    simplifying this process by providing end to end solutions.
                  </p>
                </div>
                <div className="p2pbt">
                  <a href="#contact" className="button d-block mx-auto">
                    Hire Experts
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="walletfaq">
          <div id="main">
            <div class="container">
              <div className="faq-content">
                <p className="p-mobile">Have a Question ?</p>
                <h3 className="h2-mobile h2-md">Frequently Asked Questions</h3>
              </div>
              <div class="accordion" id="faq">
                <div class="card">
                  <div class="card-header" id="faqhead1">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      How secure are wallets developed by Block Intelligence?
                    </a>
                  </div>

                  <div
                    id="faq1"
                    class="collapse"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Block Intelligence prioritizes security with robust
                      encryption and authentication protocols, ensuring your
                      assets remain safe.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead2">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="true"
                      aria-controls="faq2"
                    >
                      Can I integrate NFTs into my crypto wallet?
                    </a>
                  </div>

                  <div
                    id="faq2"
                    class="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Absolutely, our development allows seamless integration of
                      NFTs, staying at the forefront of blockchain innovation.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      What cryptocurrencies does the wallet support?
                    </a>
                  </div>

                  <div
                    id="faq3"
                    class="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Our wallets are versatile, supporting a range of
                      cryptocurrencies to provide users with diverse transaction
                      options.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead4">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq4"
                      aria-expanded="true"
                      aria-controls="faq4"
                    >
                      How can I monetize my crypto wallet?
                    </a>
                  </div>

                  <div
                    id="faq4"
                    class="collapse"
                    aria-labelledby="faqhead4"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Monetization options include transaction fees, premium
                      features, token listings, partnerships, and customization
                      services.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead5">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq5"
                      aria-expanded="true"
                      aria-controls="faq5"
                    >
                      What sets Block Intelligence apart in crypto wallet
                      development?
                    </a>
                  </div>

                  <div
                    id="faq5"
                    class="collapse"
                    aria-labelledby="faqhead5"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Block Intelligence offers tailored solutions with a focus
                      on security, innovation, and user-friendly interfaces,
                      ensuring your crypto wallet stands out in the market.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <div className="row tech">
              <div className="col-lg-12 techstacks">
                <div className="slider">
                  <div className="slide-track">
                    <div className="slide">
                      <img
                        src={require("../Images/htmllogo.webp")}
                        className="bitcoin"
                        alt="html"
                      />
                      <p>Html</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/csslogo.webp")}
                        className="bitcoin"
                        alt="Crypto Bitcoin"
                      />
                      <p>Css</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/bootsrap.webp")}
                        className="bitcoin"
                        alt="Bootstrap"
                      />
                      <p>Bootstrap</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/angularlogo.webp")}
                        className="bitcoin"
                        alt="Angular Js"
                      />
                      <p>Angular</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/reactlogo.webp")}
                        className="bitcoin"
                        alt="React Js"
                      />
                      <p>React Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/nodejslogo.webp")}
                        className="bitcoin"
                        alt="Node Js"
                      />
                      <p>Node Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/net-logo.webp")}
                        className="bitcoin"
                        alt="Dot Net"
                      />
                      <p>Dot Net</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/phplogo.webp")}
                        className="bitcoin"
                        alt="Php"
                      />
                      <p>Php</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Python-Logo.webp")}
                        className="bitcoin"
                        alt="Python"
                      />
                      <p>Python</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/flutter-logo.webp")}
                        className="bitcoin"
                        alt="Flutter"
                      />
                      <p>Flutter</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Swift_logo.webp")}
                        className="bitcoin"
                        alt="Swift"
                      />
                      <p>Swift</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/iconic-logo.webp")}
                        className="bitcoin"
                        alt="Iconic"
                      />
                      <p>Iconic</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Mongodb-logo.webp")}
                        className="bitcoin"
                        alt="Mongo DB"
                      />
                      <p>Mongo DB</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Elastic-logo.webp")}
                        className="bitcoin"
                        alt="ElasticSearch"
                      />
                      <p>ElasticSearch</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/PostgreSQL-logo.webp")}
                        className="bitcoin"
                        alt="PostgreSQL"
                      />
                      <p>PostgreSQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/mysql-logo.webp")}
                        className="bitcoin"
                        alt="My SQL"
                      />
                      <p>My SQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solidity-logo.webp")}
                        className="bitcoin"
                        alt="Solidity"
                      />
                      <p>Solidity</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/eth-logo.webp")}
                        className="bitcoin"
                        alt="Ethereum"
                      />
                      <p>Ethereum</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/hyper.webp")}
                        className="bitcoin"
                        alt="Hyperledger"
                      />
                      <p>Hyperledger</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solana-logo.webp")}
                        className="bitcoin"
                        alt="Solona"
                      />
                      <p>Solona</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
