import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Metaverse Development Company - Block Intelligence</title>
        <meta
          property="title"
          content="Metaverse Development Company - Block Intelligence"
        />
        <meta
          name="description"
          content="We are experts in metaverse development with high end features and specifications. As a metaverse development company we can develop innovative and customized metaverse solutions. "
        />

        <link
          rel="canonical"
          href="https://www.blockintelligence.io/metaverse-development-company"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="metaverse_head">
        <section className="p2p metaverse">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 banner_con simple-video mb-sx-2 mt-5">
                <h1 className="wow fadeInUp mt-5 h1tag">
                  Block Intelligence <br />
                  The Leading <span>Metaverse Development Company</span>
                </h1>
                <p className="wow fadeInUp mt-4">
                  Are you looking for a way to create immersive and interactive
                  digital experiences for your business or customers? Block
                  Intelligence, the leading metaverse development company that
                  offers innovative and customized solutions for the metaverse.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatmeta py-5">
          <div className="container-md">
            <div className="row">
              <div className="col-lg-5 mx-auto mt-4 d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/meta3.webp")}
                  className="p2pimg"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2>
                    {" "}
                    What is the <span>Metaverse</span> ?
                  </h2>
                  <p>
                    The metaverse is a term coined by science fiction author
                    Neal Stephenson in his novel Snow Crash, to describe a
                    virtual world where users can interact with each other and
                    digital objects through their avatars. The metaverse is not
                    just a single platform or application, but a network of
                    interconnected 3D virtual spaces that can be accessed
                    through various devices, such as smartphones, computers, or
                    virtual reality headsets. The metaverse can offer various
                    use cases, such as gaming, entertainment, education,
                    socializing, shopping, working, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="firstmeta py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 pt-3">
                <h2 className="mt-5">
                  Why Choose Block Intelligence for{" "}
                  <span>Metaverse Development</span> ?
                </h2>
                <p>
                  Block Intelligence is a metaverse development company that has
                  the expertise, experience, and passion to bring your metaverse
                  vision to life. We have a team of skilled and creative
                  developers, designers, and engineers who can create stunning
                  and engaging metaverse applications using the latest
                  technologies, such as blockchain, artificial intelligence,
                  augmented reality, and virtual reality.
                </p>
              </div>
              <div className="col-lg-6">
                <img
                  src={require("../Images/whatmeta.webp")}
                  className="firstmetaimg d-block mx-auto"
                  alt="p2p cryptocurrency exchange development company"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="meta-keyfeatures">
          <div className="container">
            <div>
              <h2>
                Our <span>Metaverse Development</span> Services
              </h2>
              <div className="row mt-5">
                <div class="col-lg-4 col-md-6 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-meta">
                    <p class="keyfeature-card-metatitle">
                      Metaverse Platform Development
                    </p>
                    <p class="keyfeature-card-para">
                      We can create a custom metaverse platform for your
                      business or industry, where you can host your own virtual
                      spaces and events, and offer your products and services to
                      your customers or users.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-meta">
                    <p class="keyfeature-card-metatitle">
                      Metaverse Application Development
                    </p>
                    <p class="keyfeature-card-para">
                      We can develop metaverse applications for various
                      purposes, such as gaming, education, entertainment,
                      socializing, shopping, working, and more.{" "}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-md-6 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-meta">
                    <p class="keyfeature-card-metatitle">
                      Metaverse Consulting Services
                    </p>
                    <p class="keyfeature-card-para">
                      We can provide you with metaverse consulting services,
                      where we can help you understand the metaverse concept,
                      its features, benefits, challenges, and opportunities. We
                      can also help you design and implement your metaverse
                      strategy.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-meta">
                    <p class="keyfeature-card-metatitle">
                      Metaverse NFT Marketplace development{" "}
                    </p>
                    <p class="keyfeature-card-para">
                      {" "}
                      Create and launch your own NFT marketplaces within the
                      metaverse, where users can buy, sell, and trade digital
                      assets like art, collectibles, virtual lands, and more.{" "}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6  mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-meta">
                    <p class="keyfeature-card-metatitle">
                      Metaverse Integration
                    </p>
                    <p class="keyfeature-card-para">
                      Connect your existing systems, applications, and data with
                      the metaverse, enabling seamless and secure interactions
                      between the physical and digital worlds. It also helps
                      leverage the benefits of blockchain, AI, and IoT in the
                      metaverse.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6  mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-meta">
                    <p class="keyfeature-card-metatitle">
                      Metaverse 3D space Development
                    </p>
                    <p class="keyfeature-card-para">
                      Create realistic and captivating 3D environments and
                      objects for the metaverse, using advanced techniques like
                      3D modeling, rendering, animation, and simulation. It also
                      helps optimize 3D spaces for performance, accessibility,
                      and scalability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="how-meta">
          <div className="container-fluid">
            <div className="mb-2">
              <h2 className="my-4">
                Benefits of <span>Metaverse Development</span>
              </h2>
              <p className="text-center text-white">
                Our metaverse development services can provide you with the
                following benefits for your business or customers
              </p>
            </div>

            <div className="row">
              <div className="col-md-12 d1none my-4">
                <h3 className="title2">
                  <img
                    src={require("../Images/benefitsmeta.webp")}
                    class="my-5"
                    width="100%"
                    alt="1"
                  />
                </h3>
              </div>
            </div>

            <div className="row mt-5 detailhidden ">
              <div class="col-lg-4 col-md-6 mb-5 col-sm-12 firstrow">
                <div class="keyfeature-card-meta">
                  <p class="keyfeature-card-metatitle">
                    Enhanced Customer Engagement
                  </p>
                  <p class="keyfeature-card-para">
                    By creating metaverse applications, you can offer your
                    customers a new and exciting way to engage with your brand,
                    products, and services, and increase their loyalty and
                    retention. You can also attract new customers and expand
                    your market by reaching a global and diverse audience in the
                    metaverse.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-5 col-sm-12 firstrow">
                <div class="keyfeature-card-meta">
                  <p class="keyfeature-card-metatitle">
                    Increased Revenue Opportunities
                  </p>
                  <p class="keyfeature-card-para">
                    By creating metaverse applications, you can generate new
                    revenue streams and monetize your virtual assets and
                    experiences in the metaverse. You can also leverage the
                    power of blockchain and cryptocurrency to enable
                    peer-to-peer transactions, micropayments, and digital
                    economies in the metaverse.
                  </p>
                </div>
              </div>
              <div class="col-lg-4  col-md-6 mb-5 col-sm-12 firstrow">
                <div class="keyfeature-card-meta">
                  <p class="keyfeature-card-metatitle">
                    Improved Operational Efficiency
                  </p>
                  <p class="keyfeature-card-para">
                    By creating metaverse applications, you can improve your
                    operational efficiency and productivity, by enabling remote
                    collaboration, communication, and training in the metaverse.
                    You can also reduce your costs and risks, by using the
                    metaverse as a simulation and testing environment for your
                    products and services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features-meta">
          <div className="container">
            <div className="feature-content text-white text-center pb-3">
              <h2>
                Features of Our <span>Metaverse Development</span>
              </h2>
              <p>
                Our metaverse development services are based on the following
                key features that make the metaverse a unique and powerful
                digital environment
              </p>
            </div>
            <div className="feature-card-row">
              <div className="row justify-content-center">
                <div className="col-md-6 mb-3 col-lg-4">
                  <div className="feature-card-meta">
                    <p className="feature-card-metatitle">Immersion</p>
                    <p class="keyfeature-card-para">
                      We create metaverse applications that provide a high level
                      of immersion, where users can feel like they are present
                      in the virtual world, and interact with it in a natural
                      and realistic way
                    </p>
                  </div>
                </div>
                <div className="col-md-6  mb-3 col-lg-4">
                  <div className="feature-card-meta">
                    <p className="feature-card-metatitle">Interactivity</p>
                    <p class="keyfeature-card-para">
                      We create metaverse applications that provide a high level
                      of interactivity, where users can interact with each other
                      and the digital objects in the virtual world, using their
                      avatars, gestures, voice, and text
                    </p>
                  </div>
                </div>
                <div className="col-md-6  mb-3 col-lg-4">
                  <div className="feature-card-meta">
                    <p className="feature-card-metatitle">Decentralization</p>
                    <p class="keyfeature-card-para">
                      This feature enables users to have more control and
                      ownership over their data and assets in the metaverse, by
                      using distributed and peer-to-peer networks, such as
                      blockchain, that do not rely on any central authority or
                      intermediary.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-3 col-lg-4">
                  <div className="feature-card-meta">
                    <p className="feature-card-metatitle">
                      Privacy and security
                    </p>
                    <p class="keyfeature-card-para">
                      This feature protects users' personal and financial
                      information in the metaverse, by using encryption,
                      authentication, and verification methods, such as
                      cryptography, digital signatures, and biometrics, that
                      ensure the confidentiality, integrity, and validity of the
                      data and transactions.
                    </p>
                  </div>
                </div>
                <div className="col-md-6  mb-3 col-lg-4">
                  <div className="feature-card-meta">
                    <p className="feature-card-metatitle">Digital currency</p>
                    <p class="keyfeature-card-para">
                      This feature enables users to exchange value and pay for
                      goods and services in the metaverse, by using digital
                      tokens, such as cryptocurrencies, stablecoins, or
                      non-fungible tokens, that are powered by blockchain
                      technology and smart contracts.
                    </p>
                  </div>
                </div>
                <div className="col-md-6  mb-3 col-lg-4">
                  <div className="feature-card-meta">
                    <p className="feature-card-metatitle">3D virtual space</p>
                    <p class="keyfeature-card-para">
                      This feature provides users with a realistic and
                      captivating 3D environment in the metaverse, by using
                      advanced techniques, such as 3D modeling, rendering,
                      animation, and simulation, that create and display
                      high-quality graphics and sounds.
                    </p>
                  </div>
                </div>

                <div className="col-md-6  mb-3 col-lg-4">
                  <div className="feature-card-meta">
                    <p className="feature-card-metatitle">Accessibility</p>
                    <p class="keyfeature-card-para">
                      This feature allows users to access and enjoy the
                      metaverse from anywhere and anytime, by using various
                      devices, platforms, and protocols, such as smartphones,
                      computers, virtual reality headsets, augmented reality
                      glasses, and web browsers, that enable seamless and secure
                      connectivity and compatibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="firstmeta">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <img
                  src={require("../Images/metalast.webp")}
                  className="firstmetaimg2 d-block mx-auto"
                  alt="p2p crypto exchange development company"
                  width="50%"
                />
              </div>
              <div className="col-lg-6">
                <h2>
                  Block Intelligence <span>Metaverse Development</span>
                </h2>

                <p>
                  If you are interested in our metaverse development services,
                  or want to learn more about the metaverse and how it can
                  benefit your business or customers, contact us today. We would
                  love to hear from you and discuss your metaverse project with
                  you. We can provide you with a free consultation and a quote
                  for your metaverse development. We look forward to working
                  with you and creating amazing metaverse applications for you.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <div className="row tech">
              <div className="col-lg-12 techstacks">
                <div className="slider">
                  <div className="slide-track">
                    <div className="slide">
                      <img
                        src={require("../Images/htmllogo.webp")}
                        className="bitcoin"
                        alt="html"
                      />
                      <p>Html</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/csslogo.webp")}
                        className="bitcoin"
                        alt="Crypto Bitcoin"
                      />
                      <p>Css</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/bootsrap.webp")}
                        className="bitcoin"
                        alt="Bootstrap"
                      />
                      <p>Bootstrap</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/angularlogo.webp")}
                        className="bitcoin"
                        alt="Angular Js"
                      />
                      <p>Angular</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/reactlogo.webp")}
                        className="bitcoin"
                        alt="React Js"
                      />
                      <p>React Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/nodejslogo.webp")}
                        className="bitcoin"
                        alt="Node Js"
                      />
                      <p>Node Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/net-logo.webp")}
                        className="bitcoin"
                        alt="Dot Net"
                      />
                      <p>Dot Net</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/phplogo.webp")}
                        className="bitcoin"
                        alt="Php"
                      />
                      <p>Php</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Python-Logo.webp")}
                        className="bitcoin"
                        alt="Python"
                      />
                      <p>Python</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/flutter-logo.webp")}
                        className="bitcoin"
                        alt="Flutter"
                      />
                      <p>Flutter</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Swift_logo.webp")}
                        className="bitcoin"
                        alt="Swift"
                      />
                      <p>Swift</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/iconic-logo.webp")}
                        className="bitcoin"
                        alt="Iconic"
                      />
                      <p>Iconic</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Mongodb-logo.webp")}
                        className="bitcoin"
                        alt="Mongo DB"
                      />
                      <p>Mongo DB</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Elastic-logo.webp")}
                        className="bitcoin"
                        alt="ElasticSearch"
                      />
                      <p>ElasticSearch</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/PostgreSQL-logo.webp")}
                        className="bitcoin"
                        alt="PostgreSQL"
                      />
                      <p>PostgreSQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/mysql-logo.webp")}
                        className="bitcoin"
                        alt="My SQL"
                      />
                      <p>My SQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solidity-logo.webp")}
                        className="bitcoin"
                        alt="Solidity"
                      />
                      <p>Solidity</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/eth-logo.webp")}
                        className="bitcoin"
                        alt="Ethereum"
                      />
                      <p>Ethereum</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/hyper.webp")}
                        className="bitcoin"
                        alt="Hyperledger"
                      />
                      <p>Hyperledger</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solana-logo.webp")}
                        className="bitcoin"
                        alt="Solona"
                      />
                      <p>Solona</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
