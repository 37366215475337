import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Pages/Home'
import Blockchain from './Pages/Blockchain'
import AI from './Pages/AI'
import PageNotfound from './Pages/Page_Not_found'
import CentralizedCex from './Pages/Centralized cex'
import Defi from './Pages/Defi'
import Nft from './Pages/nft'
import P2p from './Pages/p2p'
import Dex from './Pages/Dex';
import Gamedevelopment from './Pages/game'
import Metaverse from './Pages/metaverse'
import Token from './Pages/Token'
import Smartcontract from './Pages/smartcontract'
import Comingsoon from './Pages/Coming_soon'
import Web3 from './Pages/web3'
import Arbitragebot from './Pages/Arbitragebot'
import CryptoWallet from './Pages/Cryptowallet'
import Paymentgateway from './Pages/Paymentgateway'
import Opensea from './Pages/Opensea'
import Rarible from './Pages/Rarible'
import Cryptoexchage from './Pages/CryptoExchange'


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={< Home />} />
          <Route path="/blockchain" element={< Blockchain />} />
          <Route path="/ai" element={< AI />} />
          <Route path="*" element={< PageNotfound />} />
          <Route path="/soon" element={< Comingsoon />} />
          <Route path="/rarible-clone-script" element={< Rarible />} />
          <Route path="/opensea-clone-script" element={< Opensea />} />
          <Route path="/crypto-trading-bot-development" element={< Arbitragebot />} />
          <Route path="/crypto-exchange-development-company" element={< Cryptoexchage />} />

          <Route path="/crypto-wallet-development-company" element={< CryptoWallet />} />
          <Route path="/crypto-paymentgateway-development-company" element={< Paymentgateway/>} />
          <Route path="/centralized-crypto-exchange-development-company" element={< CentralizedCex />} />
          <Route path="/defi-development-company" element={< Defi />} />
          <Route path="/nft-marketplace-development-company" element={< Nft />} />
          <Route path="/p2p-crypto-exchange-development" element={< P2p />} />
          <Route path="/dex-development-company" element={< Dex />} />
          <Route path="/metaverse-development-company" element={< Metaverse />} />
          <Route path="/web3-game-development-company" element={< Gamedevelopment />} />
          <Route path="/crypto-token-development" element={< Token />} />
          <Route path="/smart-contract-audit-company" element={< Smartcontract />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  )
}
