import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
    const navigate = useNavigate();
    const sliderRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const [ref, inView] = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 100);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isScrolled && sliderRef.current) {
            sliderRef.current.slickNext();
        }
    }, [isScrolled]);

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isScrolled && sliderRef.current) {
                sliderRef.current.slickNext();
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [isScrolled]);

    return (
        <>
            <Helmet>
                <title>Opensea clone script</title>
                <meta
                    property="title"
                    content="Opensea clone script"
                />
                <meta
                    name="description"
                    content="Launch your own NFT Marketplace like opensea with opensea clone script offered by Block Intelligence. We offer a ready-made and customizable solution of NFT Marketplace development."
                />
                <link
          rel="canonical"
          href="https://www.blockintelligence.io/opensea-clone-script"
        />
            </Helmet>
            <Header />
            <Contactbar />
            <div className="opensea_head">
                <section className="p2p Opensea">
                    <div className="container">
                        <div className="row pt-5">
                        <div className="col-lg-6 mt-5 banner_con simple-video mb-sx-2">
                                <h1 className="wow mt-5 fadeInUp text-black h1tag">
                                    <span>Opensea  clone script</span>
                                </h1>
                                <p className="wow fadeInUp text-white mt-4">Launch your own NFT marketplace like OpenSea. Block Intelligence, the leading NFT marketplace development company that offers OpenSea clone script development service. With our OpenSea clone script, you can create a customized, secure, and user-friendly NFT marketplace in no time.  </p>
                                <div className="p2pbt mt-5">
                                    <a href="#contact" className="button">
                                        Get Demo
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="whatsmartcontract whatopensea py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                                <img
                                    src={require("../Images/openseaa2.webp")}
                                    width="90%"
                                    alt="p2p crypto exchange development company"
                                />
                            </div>
                            <div className="col-lg-7 mt-5">
                                <div className="p2pcontent">
                                    <h2>
                                        Why Choose Block Intelligence for{" "}
                                        <span>Opensea Clone Script</span>?
                                    </h2>
                                    <p>
                                        At Block Intelligence, our Opensea clone script is developed
                                        with precision, combining innovation and security. We offer
                                        an advanced solution that allows entrepreneurs to establish
                                        their NFT marketplace efficiently, using the expertise of
                                        our seasoned developers. Choosing Block Intelligence means
                                        you can count on a prompt and efficient development process,
                                        bringing your OpenSea clone script to market sooner.

                                        <div className="mt-3">
                    <a href="https://wa.me/7738479381" target="_blank" className="button">Talk with expert</a>
                  </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="whatsmartcontract whatopensea py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-7 mt-5">
                                <div className="p2pcontent">
                                    <h2>
                                        What is <span>Opensea Clone Script</span>?
                                    </h2>
                                    <p>
                                        The Opensea clone script is a customizable, ready-to-use
                                        solution that mirrors the functionality of the original
                                        Opensea NFT marketplace.This script allows individuals or
                                        businesses to create their own NFT platforms with similar
                                        capabilities to OpenSea. Users can list their unique digital
                                        assets, such as digital art, music, or virtual collectibles,
                                        for others to buy or trade. The clone script typically
                                        includes essential features like user authentication, smart
                                        contract integration for secure transactions, and a
                                        user-friendly interface for easy navigation., tapping into
                                        the booming digital asset space.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                                <img
                                    src={require("../Images/openseaa3.webp")}
                                    width="90%"
                                    alt="p2p crypto exchange development company"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="whatsmartcontract whatopensea py-5">
                    <div className="container py-5">
                        <div className="row">

                            <div className="col-lg-12 my-5">
                                <div className="p2pcontent">
                                    <h2 className="text-center">
                                        Benefits of <span>Opensea Clone Script</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 px-4 my-3">
                                    <div className="openseacard">

                                        <img src={require('../Images/cards/customize.webp')} className="my-3 mx-auto d-block" width="20%" />
                                        <h5> Seamless Customization</h5>
                                        <p className="text-center my-3">Develop the clone script to match your brand identity, ensuring a unique user experience.
                                        </p>
                                    </div>

                                </div>
                                <div className="col-lg-4 px-4 my-3">
                                    <div className="openseacard">
                                        <img src={require('../Images/cards/customize.webp')} className="my-3 mx-auto d-block" width="20%" />
                                        <h5> Decentralized Trading</h5>
                                        <p className="text-center my-3">Facilitate secure peer-to-peer transactions, eliminating intermediaries and enhancing transparency.</p>
                                    </div>

                                </div>
                                <div className="col-lg-4 px-4 my-3">
                                    <div className="openseacard">
                                        <img src={require('../Images/cards/customize.webp')} className="my-3 mx-auto d-block" width="20%" />

                                        <h5>Scalability</h5>
                                        <p className="text-center my-3">Easily scale your platform as your user base grows, ensuring optimal performance.</p>
                                    </div>

                                </div>
                                <div className="col-lg-4 px-4 my-3">
                                    <div className="openseacard">
                                        <img src={require('../Images/cards/customize.webp')} className="my-3 mx-auto d-block" width="20%" />

                                        <h5>Smart Contract Integration</h5>
                                        <p className="text-center my-3">Use smart contracts for automated and trustless transactions, enhancing the security of your platform.</p>
                                    </div>

                                </div>
                                <div className="col-lg-4 px-4 my-3">
                                    <div className="openseacard">
                                        <img src={require('../Images/cards/customize.webp')} className="my-3 mx-auto d-block" width="20%" />

                                        <h5>Community Engagement</h5>
                                        <p className="text-center my-3">Contribute to a vibrant community by allowing artists and collectors to interact and trade their unique digital assets.</p>
                                    </div>

                                </div>
                                <div className="col-lg-4 px-4 my-3">
                                    <div className="openseacard">
                                        <img src={require('../Images/cards/customize.webp')} className="my-3 mx-auto d-block" width="20%" />

                                        <h5>Cost-Effectiveness</h5>
                                        <p className="text-center my-3">Save development time and costs with a ready-to-deploy solution, accelerating your entry into the NFT market.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="whatsmartcontract whatopensea py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="p2pcontent">
                                    <h2 className="text-center mb-5">Script Development <span>VS</span> Custom Development</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center vsscripthead">
                            <div className="col-md-5 vsscript p-0 px-4  py-5">
                                <h5 className="pt-5">
                                    Using a script means utilizing pre-built code or software that eases the development process. This method is quicker and more cost-effective, suitable for those looking to launch a NFT marketplace without unique features. However, customization options may be limited, and scalability might become a concern as the platform grows.


                                </h5>
                            </div>
                            <div className="col-md-2 vsimage p-0">
                                <img src={require('../Images/vs.webp')} width="100%" />
                            </div>

                            <div className="col-md-5 vscustom py-5 p-0 px-4">
                                <h5 className="pt-5">
                                    On the other hand, a custom OpenSea clone involves building the platform from scratch, tailoring it to specific requirements. This approach offers complete flexibility and allows for the incorporation of unique features and design elements. While it demands more time and resources, a custom solution provides a more personalized and scalable NFT marketplace.
                                </h5>

                            </div>

                        </div>
                    </div>
                </section>

                <section className="whatsmartcontract whatopensea py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="p2pcontent">
                                    <h2 className="text-center mb-5">Our Development Process</h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-5">
                            <img
                                src={require("../Images/openseaprocess.webp")}
                                width="90%"
                                className="d-block mx-auto"
                                alt="p2p crypto exchange development company"
                            />
                        </div>
                    </div>
                </section>

                <section className="whatsmartcontract whatopensea py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-7 mt-5">
                                <div className="p2pcontent">
                                    <h2>Takeaway</h2>
                                    <p>
                                        A takeaway from OpenSea clone script development is the need
                                        for a comprehensive understanding of blockchain technology,
                                        smart contracts, and NFT standards like ERC-721 and
                                        ERC-1155. Thorough testing is essential to identify and
                                        rectify potential vulnerabilities, ensuring an advanced and
                                        reliable platform. Engaging with the NFT community and
                                        staying updated on industry trends will contribute to
                                        continuous improvement and adaptation to user preferences.{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                                <img
                                    src={require("../Images/opensea7.webp")}
                                    width="90%"
                                    alt="p2p crypto exchange development company"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="openfaq">
                    <div id="main">
                        <div class="container">
                            <div className="faq-content">
                                <p className="p-mobile">Have a Question ?</p>
                                <h3 className="h2-mobile h2-md">Frequently Asked Questions</h3>
                            </div>
                            <div class="accordion" id="faq">
                                <div class="card">
                                    <div class="card-header" id="faqhead1">
                                        <a
                                            href="#"
                                            class="btn btn-header-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#faq1"
                                            aria-expanded="true"
                                            aria-controls="faq1"
                                        >Is the script customizable?</a>
                                    </div>

                                    <div
                                        id="faq1"
                                        class="collapse"
                                        aria-labelledby="faqhead1"
                                        data-parent="#faq"
                                    >
                                        <div class="card-body p-md">
                                            Yes, our Opensea clone script is highly customizable, allowing you to develop it to your specific business needs.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqhead2">
                                        <a
                                            href="#"
                                            class="btn btn-header-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#faq2"
                                            aria-expanded="true"
                                            aria-controls="faq2"
                                        >How secure are transactions on the platform?</a>
                                    </div>

                                    <div
                                        id="faq2"
                                        class="collapse"
                                        aria-labelledby="faqhead2"
                                        data-parent="#faq"
                                    >
                                        <div class="card-body p-md">We prioritize security. The script incorporates robust features and smart contracts to ensure secure and transparent transactions.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqhead3">
                                        <a
                                            href="#"
                                            class="btn btn-header-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#faq3"
                                            aria-expanded="true"
                                            aria-controls="faq3"
                                        >Can I integrate additional features into the clone script?</a>
                                    </div>

                                    <div
                                        id="faq3"
                                        class="collapse"
                                        aria-labelledby="faqhead3"
                                        data-parent="#faq"
                                    >
                                        <div class="card-body p-md">Absolutely. Our script is designed to be extensible, enabling you to integrate additional features as your platform evolves.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqhead3">
                                        <a
                                            href="#"
                                            class="btn btn-header-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#faq3"
                                            aria-expanded="true"
                                            aria-controls="faq3"
                                        >Is technical support provided after the script is deployed?</a>
                                    </div>

                                    <div
                                        id="faq3"
                                        class="collapse"
                                        aria-labelledby="faqhead3"
                                        data-parent="#faq"
                                    >
                                        <div class="card-body p-md">
                                            Yes, we offer continuous technical support to ensure the smooth functioning of your Opensea clone script.</div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="faqhead3">
                                        <a
                                            href="#"
                                            class="btn btn-header-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#faq3"
                                            aria-expanded="true"
                                            aria-controls="faq3"
                                        >    What blockchain networks does the script support?</a>
                                    </div>

                                    <div
                                        id="faq3"
                                        class="collapse"
                                        aria-labelledby="faqhead3"
                                        data-parent="#faq"
                                    >
                                        <div class="card-body p-md">Our script is blockchain-agnostic, supporting various networks like Ethereum, Binance Smart Chain, and more for flexibility in deployment.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="techstack2 stacz">
                    <div className="container">
                        <h2 className="tech-h2">Blockchain Tech Stacks</h2>
                        <div className="stack">
                            <p>
                                Our team of blockchain developers are experts at building
                                innovative blockchain solutions on the best available blockchain
                                platform.
                            </p>
                        </div>
                        <div className="row tech">
                            <div className="col-lg-12 techstacks">
                                <div className="slider">
                                    <div className="slide-track">
                                        <div className="slide">
                                            <img
                                                src={require("../Images/htmllogo.webp")}
                                                className="bitcoin"
                                                alt="html"
                                            />
                                            <p>Html</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/csslogo.webp")}
                                                className="bitcoin"
                                                alt="Crypto Bitcoin"
                                            />
                                            <p>Css</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/bootsrap.webp")}
                                                className="bitcoin"
                                                alt="Bootstrap"
                                            />
                                            <p>Bootstrap</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/angularlogo.webp")}
                                                className="bitcoin"
                                                alt="Angular Js"
                                            />
                                            <p>Angular</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/reactlogo.webp")}
                                                className="bitcoin"
                                                alt="React Js"
                                            />
                                            <p>React Js</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/nodejslogo.webp")}
                                                className="bitcoin"
                                                alt="Node Js"
                                            />
                                            <p>Node Js</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/net-logo.webp")}
                                                className="bitcoin"
                                                alt="Dot Net"
                                            />
                                            <p>Dot Net</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/phplogo.webp")}
                                                className="bitcoin"
                                                alt="Php"
                                            />
                                            <p>Php</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/Python-Logo.webp")}
                                                className="bitcoin"
                                                alt="Python"
                                            />
                                            <p>Python</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/flutter-logo.webp")}
                                                className="bitcoin"
                                                alt="Flutter"
                                            />
                                            <p>Flutter</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/Swift_logo.webp")}
                                                className="bitcoin"
                                                alt="Swift"
                                            />
                                            <p>Swift</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/iconic-logo.webp")}
                                                className="bitcoin"
                                                alt="Iconic"
                                            />
                                            <p>Iconic</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/Mongodb-logo.webp")}
                                                className="bitcoin"
                                                alt="Mongo DB"
                                            />
                                            <p>Mongo DB</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/Elastic-logo.webp")}
                                                className="bitcoin"
                                                alt="ElasticSearch"
                                            />
                                            <p>ElasticSearch</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/PostgreSQL-logo.webp")}
                                                className="bitcoin"
                                                alt="PostgreSQL"
                                            />
                                            <p>PostgreSQL</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/mysql-logo.webp")}
                                                className="bitcoin"
                                                alt="My SQL"
                                            />
                                            <p>My SQL</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/solidity-logo.webp")}
                                                className="bitcoin"
                                                alt="Solidity"
                                            />
                                            <p>Solidity</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/eth-logo.webp")}
                                                className="bitcoin"
                                                alt="Ethereum"
                                            />
                                            <p>Ethereum</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/hyper.webp")}
                                                className="bitcoin"
                                                alt="Hyperledger"
                                            />
                                            <p>Hyperledger</p>
                                        </div>
                                        <div className="slide">
                                            <img
                                                src={require("../Images/solana-logo.webp")}
                                                className="bitcoin"
                                                alt="Solona"
                                            />
                                            <p>Solona</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FillForm />

            <Footer />
        </>
    );
}
