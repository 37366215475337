import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>
          AI and Blockchain Development Company | Block Intelligence
        </title>
        <meta
          property="title"
          content="AI and Blockchain Development Company | Block Intelligence"
        />
        <meta
          property="og:title"
          content="AI/ML and Blockchain Development Company"
        />
        <meta
          property="og:description"
          content="Block Intelligence offers an innovative AI and Blockchain development service. We have an adroit team of developed experts working with Ethereum, Solana, Polkadot, and more."
        />
        <meta property="og:site" content="https://www.blockintelligence.io/" />
        <meta
          property="og:author"
          content="Block Intelligence - Blockchain Development Company"
        />
        <meta
          property="og:site_name"
          content="Block Intelligence - Blockchain and AI/ML development Company"
        />
        <meta property="og:type" content="website"></meta>
        <meta
          name="description"
          content="We are a trusted Blockchain development Service provider offering various AI/ML and Blockchain solutions with high-end features and functionalities. Get a free demo."
        />
        <meta
          name="keywords"
          content="blockchain, AI/ML development, blockchain development company, AI/ML development company, blockchain solutions, blockchain development services, blockchain development services, blockchain development service provider, AI development company, Web3 development company, crypto exchange development company, blockchain development service."
        />
         <link
          rel="canonical"
          href="https://www.blockintelligence.io/"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <section className="home-new" ref={ref}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero">
                <Slider {...settings} ref={sliderRef}>
                  <div className="slide1">
                    <div className="hero-front"></div>
                    <div className="hero-content">
                      <div class="container">
                        <div className="hero-title1">
                          <h2 className="h1tag">
                            Artificial Intelligence
                            <br />
                            Development Company
                          </h2>
                          <p>
                            Elevate Your Business with Block Intelligence - Your
                            Premier AI Development Company. Utilize the power of
                            artificial intelligence as we transform your ideas
                            into intelligent solutions. Our team of skilled
                            experts brings advanced AI technologies to life,
                            revolutionizing industries and driving innovation.
                            Unleash the potential of AI and take your business
                            to new heights with Block Intelligence by your side.
                          </p>
                          {/* <button>Get Demo</button> */}
                          <a href="#contact" className="button">
                            Get Demo
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="slide2">
                    <div className="hero-front"></div>
                    <div className="hero-content">
                      <div className="container">
                        <div className="hero-title1">
                          <h1 className="h1tag">
                            Blockchain
                            <br />
                            Development Company
                          </h1>
                          <p>
                            Block Intelligence - Your Trusted Partner in
                            Blockchain Development. Discover innovative
                            solutions, smart contracts development, NFT
                            marketplace, decentralized exchange development and
                            more, crafted by our expert team. Join us as we pave
                            the way for a transparent and secure digital
                            revolution. Together, we'll build the blocks that
                            shape the future.
                          </p>
                          <a href="#contact" className="button">
                            Get Demo
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutus">
        <div className="about-img"></div>
        <div className="container">
          <div className="about">
            <div className="row">
              <div className="col-lg-5 about-left">
                <h2>
                  About
                  <br />
                  <span>Block Intelligence</span>
                </h2>

                <p>
                  Block Intelligence is unlocking the innovative future for
                  Blockchain development and AI development. We have a talented
                  team of Blockchain developers and AI developers who can assist
                  you throughout the development process. From developing
                  innovative NFT marketplaces, building robust DeFi platforms,
                  and creating immersive Metaverse experiences, to crafting
                  secure DEX and CEX solutions – we do it all. Whether you need
                  a customized blockchain solution or a project built from
                  scratch, our team is here to bring your vision to life. Join
                  us in shaping the future of technology.
                </p>
              </div>
              <div className="col-lg-7 about-right">
                <img
                  src={require("../Images/bg/image (1).webp")}
                  className="bitcoin"
                  alt="blockchain"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whybi">
        <div className="container">
          <div className="why">
            <div className="row">
              <div className="col-lg-4 why-left">
                <div className="bg-linear"></div>
                <img
                  src={require("../Images/bg/image (2).webp")}
                  className="whychoosen"
                  alt="AI Development"
                />
              </div>
              <div className="col-lg-8 why-right">
                <h2>
                  Why Choose Block Intelligence
                  <br /> for Blockchain Development?
                </h2>
                <hr />
                <div className="top">
                  <p>
                    Choose Block Intelligence for blockchain development
                    services and experience the power of innovative solutions,
                    expert knowledge, enhanced security, and personalized
                    service. Let us be your trusted partner in unlocking the
                    full potential of blockchain technology for your business.
                  </p>
                </div>
                <div className="row up">
                  <div className="col-lg-6 one">
                    <div className="why-card">
                      <img
                        src={require("../Images/why-logo1.webp")}
                        className="bitcoin"
                        alt="Blockchain Solution"
                      />
                      <p>
                        Expertise: Skilled professionals delivering tailored
                        solutions with extensive blockchain knowledge.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 one">
                    <div className="why-card">
                      <img
                        src={require("../Images/why-logo2.webp")}
                        className="bitcoin"
                        alt="AI/ML Development Company"
                      />
                      <p>
                        Innovation: Stay ahead with cutting-edge solutions that
                        leverage the latest blockchain trends.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row down">
                  <div className="col-lg-6 one">
                    <div className="why-card">
                      <img
                        src={require("../Images/why-logo3.webp")}
                        className="bitcoin"
                        alt="Blockchain Development Company"
                      />
                      <p>
                        Security: Robust measures protecting your data,
                        transactions, and smart contracts.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 one">
                    <div className="why-card">
                      <img
                        src={require("../Images/why-logo4.webp")}
                        className="bitcoin"
                        alt="Why Block Intelligence"
                      />
                      <p>
                        Client-Centric Approach: Prioritizing your success
                        through personalized service and exceeding expectations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services">
        <div className="container">
          <h2> Our Expertise Blockchain Services</h2>
          <p className="serv-p">
            We assist startups and industries to integrate secure decentralized
            blockchain solutions to level up their businesses. Our blockchain
            services are developed with cutting-edge features and
            specifications.
          </p>
          <div className="row serv1">
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/Centralized-logo.webp")}
                    className="bitcoin"
                    alt="Centralized"
                  />
                  <p className="serv-title">Centralized Exchange Development</p>
                  <p className="serv-desc">
                    We offer a Seamless cryptocurrency exchange platform
                    development solution, featuring robust security measures and
                    a user-friendly trading experience that empowers your users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <div className="serv-tit">
                    <img
                      src={require("../Images/nft-logo.webp")}
                      className="bitcoin"
                      alt="NFT"
                    />
                    <p className="serv-title">NFT MarketPlace Development</p>
                    <p className="serv-desc">
                      Transform the digital art landscape with Block
                      Intelligence's NFT Marketplace development. Empower
                      creators and collectors to thrive in a vibrant ecosystem
                      of digital assets.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/DeCentralized-logo.webp")}
                    className="bitcoin"
                    alt="Decentralized"
                  />
                  <p className="serv-title">DEX Development</p>
                  <p className="serv-desc">
                    Unlock the future of trading with our Decentralized Exchange
                    platform. Experience secure, transparent, and P2P
                    transactions in a seamless trading environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row serv1">
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/Decentralized-Finance-logo.webp")}
                    className="bitcoin"
                    alt="DIFI"
                  />
                  <p className="serv-title">DeFi Development</p>
                  <p className="serv-desc">
                    We specialize in secure & scalable DeFi platforms: DeFi
                    wallet, exchange, Dapp, lending, & DeFi token development.
                    Unleash the power of decentralized finance with Block
                    Intelligence.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv-card"></div>
                <div className="serv">
                  <img
                    src={require("../Images/P2P-logo.webp")}
                    className="bitcoin"
                    alt="P2P"
                  />
                  <p className="serv-title">P2P Crypto Exchange Development</p>
                  <p className="serv-desc">
                    We develop a Peer-to-peer cryptocurrency exchange platform
                    that connects buyers and sellers for secure, scalable,
                    direct transactions in a decentralized environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/Token-logo.webp")}
                    className="bitcoin"
                    alt="Token Development"
                  />
                  <p className="serv-title">Token Development</p>
                  <p className="serv-desc">
                    Block Intelligence empowers your cryptocurrency business
                    with powerful tokens across various blockchain platforms,
                    upgrading your capabilities for unprecedented growth and
                    success.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row serv1">
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/Smart Contract-logo.webp")}
                    className="bitcoin"
                    alt="Smart Contract"
                  />
                  <p className="serv-title">Smart Contract Auditing</p>
                  <p className="serv-desc">
                    Ensure smart contract integrity with Block Intelligence's
                    smart contract auditing. Trust our expertise to identify
                    vulnerabilities and enhance security measures, providing you
                    with confidence.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/Metaverse-logo.webp")}
                    className="bitcoin"
                    alt="Metaverse"
                  />
                  <p className="serv-title">Metaverse Development</p>
                  <p className="serv-desc">
                    With our expertise, we empower you to create immersive
                    digital experiences that redefine reality, captivate your
                    audience, and leave an unforgettable impression, taking
                    engagement to unique heights.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/web3-logo.webp")}
                    className="bitcoin"
                    alt="web-3"
                  />
                  <p className="serv-title">Web3 Solutions</p>
                  <p className="serv-desc">
                    Embrace the decentralized future with our Web3 services,
                    empowering your applications with seamless integration,
                    secure transactions, and a pathway to unlock the full
                    potential of the decentralized ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row serv1">
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv-card"></div>
                <div className="serv">
                  <img
                    src={require("../Images/Game-logo.webp")}
                    className="bitcoin"
                    alt="Game Development"
                  />
                  <p className="serv-title">Game Development</p>
                  <p className="serv-desc">
                    Level up your gaming experience with Block Intelligence's
                    game development expertise. we bring your gaming vision to
                    life, creating immersive worlds that leave a lasting
                    impression.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/serviceslogo.webp")}
                    className="bitcoin"
                    alt="Blockchain Development"
                  />
                  <p className="serv-title">
                    EVM Compatible Blockchain Development
                  </p>
                  <p className="serv-desc">
                    Leverage Ethereum's power for seamless interoperability,
                    enabling your smart contracts and decentralized projects to
                    reach new heights with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/bot-logo.webp")}
                    className="bitcoin"
                    alt="Trading Bot"
                  />
                  <p className="serv-title">Market Making Bot Development</p>
                  <p className="serv-desc">
                    Our advanced algorithms provide liquidity and maximize your
                    trading potential, ensuring efficient and profitable market
                    participation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row serv1">
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv-card"></div>
                <div className="serv">
                  <img
                    src={require("../Images/token-list.png")}
                    className="bitcoin"
                    alt="Token Listing"
                  />
                  <p className="serv-title">Crypto Token Listing</p>
                  <p className="serv-desc">
                    Boost the visibility and liquidity of your crypto token with
                    Block Intelligence's listing service. Gain access to major
                    exchanges and expand your investor base for increased market
                    opportunities and growth potential.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/p2e-gaming.webp")}
                    className="bitcoin"
                    alt="Gaming"
                  />
                  <p className="serv-title">P2E Gaming</p>
                  <p className="serv-desc">
                    Immerse yourself in the future of gaming with Block
                    Intelligence's P2E service. Earn real value from virtual
                    worlds, combining gaming and blockchain for thrilling,
                    interactive experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/vc-fund.webp")}
                    className="bitcoin"
                    alt="VC Funding"
                  />
                  <p className="serv-title">Incubation & VC Funding</p>
                  <p className="serv-desc">
                    Accelerate your project's growth with Block Intelligence's
                    incubation and VC funding service. Unlock access to expert
                    guidance, resources, and financial support to fuel your
                    blockchain venture's success.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row serv1">
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv-card"></div>
                <div className="serv">
                  <img
                    src={require("../Images/web3-consult.webp")}
                    className="bitcoin"
                    alt="web3"
                  />
                  <p className="serv-title">Blockchain Web3 Consultancy</p>
                  <p className="serv-desc">
                    Navigate the complexities of blockchain and Web3 with ease
                    through our consultancy service. Leverage our expertise to
                    drive innovation, enhance decentralization, and unlock the
                    full potential of the decentralized web.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/block-market.webp")}
                    className="bitcoin"
                    alt="Blockchain Marketing"
                  />
                  <p className="serv-title">Blockchain Marketing</p>
                  <p className="serv-desc">
                    Fuel your blockchain project's success with our marketing
                    expertise. Leverage strategic campaigns and targeted
                    messaging to build awareness, attract investors, and achieve
                    your goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="serv-bgcard">
                <div className="serv">
                  <div className="serv-card"></div>
                  <img
                    src={require("../Images/graphics.webp")}
                    className="bitcoin"
                    alt="Graphics & Branding"
                  />
                  <p className="serv-title">Graphics and Branding</p>
                  <p className="serv-desc">
                    Create a strong brand presence with captivating graphics and
                    branding. Stand out from the competition with visually
                    stunning designs that resonate with your audience. Let us
                    bring your brand to life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mobileapp">
        <div className="container">
          <div className="why">
            <div className="row">

              <div className="col-lg-12 mb-4 text-center">
                <h2>
                  Mobile App Development
                </h2>
              </div>
            </div>

            <div className="row mt-4 mobileappcontent">
              <div className="col-lg-3 datas datas1 pl-3">
                <img src={require("../Images/icons/andriod.webp")} width="30px" className="mb-3" />
                <h4>Andriod App Development</h4>
                <p>Discover the possibilities of innovation with our Android app development service. We create captivating experiences customized for the Android platform, with elegant interfaces and robust functionalities. We make sure your app shines in the digital market.
</p>
              </div>
              <div className="col-lg-3 datas pl-3">
                <img src={require("../Images/icons/ios.png")} width="30px" className="mb-3" />
                <h4>IOS App Development</h4>
                <p>Boost your brand in the Apple world with our IOS app development service. We excel in creating smooth, user-friendly applications that fit perfectly with the iOS platform, giving your users a superior mobile experience.</p>
              </div>
              <div className="col-lg-3 datas pl-3">
                <img src={require("../Images/icons/crossplatform.webp")} width="30px" className="mb-3" />
                <h4>Cross-Platform App Development</h4>
                <p>Harness the potential of cross-platform app development with Block Intelligence. Expand your reach across various devices without sacrificing performance. Our experts use the latest frameworks to make your app a true multi-platform wonder.</p>
              </div>
              <div className="col-lg-3 datas pl-3 borderright">
                <img src={require("../Images/icons/prowebdev.webp")} width="30px" className="mb-3 " />
                <h4>
                  Progressive Web Development
                </h4>
                <p>Explore the future of the web with our progressive web development service. We combine the best of web and app features, creating fast, responsive web applications that attract users easily. Enhance your online presence with a web experience that adapts to technology.
</p>
              </div>
            </div>


          </div>
        </div>
      </section>

      {/* <section className="clientstack">
        <div className="container">
          <h2 className="text-white">Our Clients</h2>

          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/Astrologer.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/Audrey.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>

            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/advocatealok.png')} width="100%" className="mx-auto d-block" />

              </div>

            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/dexverry.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>

            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/doodle.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/coinpad.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>

            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/cryptobanks.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/poolfinance.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/amd.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/maalscan.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/ex99.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/infinitypad.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/solalgo.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="clientcard pt-3">
                <img src={require('../Images/client/dtt.png')} width="100%" className="mx-auto d-block" />

              </div>
            </div>

          </div>

        </div>
      </section>
 */}


      <section className="techstack">
        <div className="container">
          <h2 className="tech-h2">Blockchain Tech Stacks</h2>
          <div className="stack">
            <p>
              Our team of blockchain developers are experts at building
              innovative blockchain solutions on the best available blockchain
              platform.
            </p>
          </div>
          <div className="row tech">
            <div className="col-lg-12 techstacks">
              <div className="slider">
                <div className="slide-track">
                  <div className="slide">
                    <img
                      src={require("../Images/htmllogo.webp")}
                      className="bitcoin"
                      alt="html"
                    />
                    <p>Html</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/csslogo.webp")}
                      className="bitcoin"
                      alt="Css"
                    />
                    <p>Css</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/bootsrap.webp")}
                      className="bitcoin"
                      alt="Bootstrap"
                    />
                    <p>Bootstrap</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/angularlogo.webp")}
                      className="bitcoin"
                      alt="angular"
                    />
                    <p>Angular</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/reactlogo.webp")}
                      className="bitcoin"
                      alt="React JS"
                    />
                    <p>React Js</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/nodejslogo.webp")}
                      className="bitcoin"
                      alt="Node Js"
                    />
                    <p>Node Js</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/net-logo.webp")}
                      className="bitcoin"
                      alt="Dot Net"
                    />
                    <p>Dot Net</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/phplogo.webp")}
                      className="bitcoin"
                      alt=" Php"
                    />
                    <p>Php</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Python-Logo.webp")}
                      className="bitcoin"
                      alt="Phyton "
                    />
                    <p>Phyton</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/flutter-logo.webp")}
                      className="bitcoin"
                      alt="Flutter"
                    />
                    <p>Flutter</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Swift_logo.webp")}
                      className="bitcoin"
                      alt="Swift"
                    />
                    <p>Swift</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/iconic-logo.webp")}
                      className="bitcoin"
                      alt="Iconic"
                    />
                    <p>Iconic</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Mongodb-logo.webp")}
                      className="bitcoin"
                      alt="Mongo DB"
                    />
                    <p>Mongo DB</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/Elastic-logo.webp")}
                      className="bitcoin"
                      alt="ElasticSearch"
                    />
                    <p>ElasticSearch</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/PostgreSQL-logo.webp")}
                      className="bitcoin"
                      alt="PostgreSQL"
                    />
                    <p>PostgreSQL</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/mysql-logo.webp")}
                      className="bitcoin"
                      alt="MY SQL"
                    />
                    <p>My SQL</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/solidity-logo.webp")}
                      className="bitcoin"
                      alt="Solidity"
                    />
                    <p>Solidity</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/eth-logo.webp")}
                      className="bitcoin"
                      alt="Ethereum"
                    />
                    <p>Ethereum</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/hyper.webp")}
                      className="bitcoin"
                      alt="Hyperledger"
                    />
                    <p>Hyperledger</p>
                  </div>
                  <div className="slide">
                    <img
                      src={require("../Images/solana-logo.webp")}
                      className="bitcoin"
                      alt="Solona"
                    />
                    <p>Solona</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="homesecurity">
        <div className="about-img"></div>
        <div className="container">
          <div className="about">
            <div className="row">
              <div className="col-lg-12 about-left">
                <h2>
                  <span>Security Features</span>
                </h2>

                <p>In addition to developing your software with the best security features, we always update the security to meet the needs of our clients and projects.</p>
              </div>
             
            </div>
          </div>
        </div>
      </section> */}
      <FillForm />
      <Footer />
    </>
  );
}
