import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Smart Contract Audit Company</title>
        <meta property="title" content="Smart Contract Audit Company" />
        <meta
          name="description"
          content="Block Intelligence is a smart contract audit company that provides reliable security analysis for your blockchain projects. We help you identify and fix any vulnerabilities, bugs, or errors in your smart contracts."
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/smart-contract-audit-company"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="token_head smartcontractaudit">
        <section className="p2p tokens ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp h1tag">
                  <span>Smart Contract</span> Audit Company - Block Intelligence
                </h1>
                <p className="wow fadeInUp mt-4">
                  Block Intelligence is a leading smart contract audit company
                  that provides comprehensive and reliable security solutions
                  for blockchain-based applications. We also help you comply
                  with the best practices and standards of smart contract
                  development and deployment.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mt-5">
                <div className="">
                  <img
                    src={require("../Images/smartcontractbg.webp")}
                    className="d-block mx-auto mt-5"
                    width="100%"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/smartcontract2.webp")}
                  className="p2pimg"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2>
                    Overview of <span>Smart Contract</span> Auditing
                  </h2>
                  <p>
                    Smart contract auditing is a process of analyzing and
                    testing the smart contract code to ensure its security,
                    quality, and efficiency. Smart contracts are self-executing
                    agreements that run on a blockchain network, such as
                    Ethereum, Binance Smart Chain, Solana, or Polkadot. They
                    enable trustless and transparent transactions between
                    multiple parties, without the need for intermediaries or
                    central authorities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    Process of Our <span>Smart Contract</span> Audit
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    We follow a simple and streamlined process for our smart
                    contract audit service, which consists of the following
                    steps
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/smartProcess.webp")}
                  width="100%"
                  className="processmartimg"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="mb-3">
                    Why <span>Smart Contract</span> Auditing?
                  </h2>
                  <p className="">
                    Smart contracts are also prone to various risks and
                    challenges, such as Coding errors, Security vulnerabilities,
                    Compliance issues. Therefore, smart contract auditing is
                    essential to ensure the security, quality, and efficiency of
                    your smart contracts.
                  </p>
                  <p></p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    Detect and fix any coding errors or security vulnerabilities
                    in your smart contract code
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    Verify the functionality and logic of your smart contract
                    and ensure it matches your specifications and expectations
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    Optimize the performance and gas efficiency of your smart
                    contract and reduce the cost of deployment and execution
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    Comply with the best practices and standards of smart
                    contract development and deployment
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    Enhance the trust and confidence of your users, investors,
                    and partners in your smart contract and your blockchain
                    project
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5 pt-5">
                <img
                  src={require("../Images/smartcontract3.webp")}
                  className="mx-auto d-block"
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    Why Choose Block Intelligence For{" "}
                    <span>Smart Contract</span> Auditing
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    Block Intelligence is a leading blockchain and AI
                    development company that offers smart contract auditing
                    service for various blockchain platforms, such as Ethereum,
                    Binance Smart Chain, Solana, or Polkadot. We have a team of
                    expert smart contract auditors who can help you ensure the
                    quality, reliability, and security of your smart contracts.
                    Block Intelligence is a trusted and reputable smart contract
                    audit company.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartexpertise.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Expertise</h3>
                  <p>
                    We have a team of expert and certified auditors who have
                    extensive knowledge and experience in smart contract
                    development and auditing. They are proficient in various
                    programming languages, frameworks, and tools and also
                    familiar with various blockchain platforms.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartquality.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Quality</h3>
                  <p>
                    We follow a rigorous and systematic approach to smart
                    contract auditing, using both manual and automated methods.
                    We use various tools and techniques. We also use various
                    tools and software.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartsecurity.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Security</h3>
                  <p>
                    We adhere to the highest standards of security and
                    confidentiality in our smart contract auditing services. We
                    use encryption, authentication, and authorization mechanisms
                    to protect your smart contract code and data.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smarttranspanrency.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Transparency</h3>
                  <p>
                    We provide you with a detailed and comprehensive audit
                    report that contains all the information.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartsupport.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Support</h3>
                  <p>
                    We provide you with ongoing support and assistance after the
                    completion of the smart contract audit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    Our Secure <span>Smart Contract</span> Audit Services
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    Our secure smart contract audit service covers the following
                    aspects of your smart contract
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/codereview.webp")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Code Review</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/securityanalyze.png")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Security Analysis</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/codetesting.webp")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Functionality Testing</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/fullaudit.webp")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Full Audit</h3>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/complaincecheck.webp")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Compliance Check</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/auditreport.webp")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Audit Report Creation</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/thread.webp")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Threat Analysis</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 p-2 my-2">
                <div className="securecontract d-flex">
                  <img
                    src={require("../Images/icons/optimization.webp")}
                    className="smartSecure"
                    width="20%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Optimization</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container-fluid py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    <span>Smart Contract</span> Audit Service on Different
                    Blockchain Networks
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    Block Intelligence provides smart contract audit service on
                    different blockchain networks
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-lg-1 col-md-3 col-sm-3  my-3">
                <img
                  src={require("../Images/icons/eth.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">Ethereum</h3>
              </div>
              <div className="col-lg-1 col-md-3 col-sm-3 my-3">
                <img
                  src={require("../Images/icons/bnb.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">BNB</h3>
              </div>
              <div className="col-lg-1 col-md-3 col-sm-3 my-3">
                <img
                  src={require("../Images/icons/Avalanche.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">Avalanche</h3>
              </div>
              <div className="col-lg-1 col-md-3 col-sm-3 my-3">
                <img
                  src={require("../Images/icons/Cardano.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">Cardano</h3>
              </div>
              <div className="col-lg-1 col-md-3 col-sm-3 my-3">
                <img
                  src={require("../Images/icons/polkadot.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">Polkadot</h3>
              </div>
              <div className="col-lg-1 col-md-3 col-sm-3 my-3">
                <img
                  src={require("../Images/icons/solana.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">Solana</h3>
              </div>
              <div className="col-lg-1 col-md-3 col-sm-3 my-3">
                <img
                  src={require("../Images/icons/polygon.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">Polygon</h3>
              </div>
              <div className="col-lg-1 col-md-3 col-sm-3 my-3">
                <img
                  src={require("../Images/icons/xrb.webp")}
                  width="100%"
                  className="smartnetwork d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
                <h3 className="text-center mt-3">XRP</h3>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent text-center">
                  <h2>
                    Hire Experts for <span>Smart Contract</span> Auditing
                  </h2>
                  <p className="text-center my-4">
                    Smart contracts are the backbone of any blockchain-based
                    application, but they can also be the source of many risks
                    and vulnerabilities. That's why you need to hire experts for
                    smart contract auditing from Block Intelligence, a leading
                    blockchain and AI development company. Block Intelligence
                    has a team of expert smart contract auditors who can help
                    you ensure the quality, reliability, and security of your
                    smart contracts on various blockchain platforms, such as
                    Ethereum, Binance Smart Chain, Solana, or Polkadot. Block
                    Intelligence can help you detect and fix any errors, bugs,
                    or issues in your smart contracts before deployment, and
                    provide you with a detailed and comprehensive report of
                    their findings and recommendations. Don't compromise the
                    success and safety of your blockchain project. Hire experts
                    for smart contract auditing from Block Intelligence today.
                  </p>
                </div>
                <div className="p2pbt">
                  <a href="#contact" className="button d-block mx-auto">
                    Hire Experts
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <div className="row tech">
              <div className="col-lg-12 techstacks">
                <div className="slider">
                  <div className="slide-track">
                    <div className="slide">
                      <img
                        src={require("../Images/htmllogo.webp")}
                        className="bitcoin"
                        alt="html"
                      />
                      <p>Html</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/csslogo.webp")}
                        className="bitcoin"
                        alt="Crypto Bitcoin"
                      />
                      <p>Css</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/bootsrap.webp")}
                        className="bitcoin"
                        alt="Bootstrap"
                      />
                      <p>Bootstrap</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/angularlogo.webp")}
                        className="bitcoin"
                        alt="Angular Js"
                      />
                      <p>Angular</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/reactlogo.webp")}
                        className="bitcoin"
                        alt="React Js"
                      />
                      <p>React Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/nodejslogo.webp")}
                        className="bitcoin"
                        alt="Node Js"
                      />
                      <p>Node Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/net-logo.webp")}
                        className="bitcoin"
                        alt="Dot Net"
                      />
                      <p>Dot Net</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/phplogo.webp")}
                        className="bitcoin"
                        alt="Php"
                      />
                      <p>Php</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Python-Logo.webp")}
                        className="bitcoin"
                        alt="Python"
                      />
                      <p>Python</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/flutter-logo.webp")}
                        className="bitcoin"
                        alt="Flutter"
                      />
                      <p>Flutter</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Swift_logo.webp")}
                        className="bitcoin"
                        alt="Swift"
                      />
                      <p>Swift</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/iconic-logo.webp")}
                        className="bitcoin"
                        alt="Iconic"
                      />
                      <p>Iconic</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Mongodb-logo.webp")}
                        className="bitcoin"
                        alt="Mongo DB"
                      />
                      <p>Mongo DB</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Elastic-logo.webp")}
                        className="bitcoin"
                        alt="ElasticSearch"
                      />
                      <p>ElasticSearch</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/PostgreSQL-logo.webp")}
                        className="bitcoin"
                        alt="PostgreSQL"
                      />
                      <p>PostgreSQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/mysql-logo.webp")}
                        className="bitcoin"
                        alt="My SQL"
                      />
                      <p>My SQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solidity-logo.webp")}
                        className="bitcoin"
                        alt="Solidity"
                      />
                      <p>Solidity</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/eth-logo.webp")}
                        className="bitcoin"
                        alt="Ethereum"
                      />
                      <p>Ethereum</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/hyper.webp")}
                        className="bitcoin"
                        alt="Hyperledger"
                      />
                      <p>Hyperledger</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solana-logo.webp")}
                        className="bitcoin"
                        alt="Solona"
                      />
                      <p>Solona</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
