import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import GameDev from "../Components/Game-Dev";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Web3 Game Development Company</title>
        <meta property="title" content="Web3 Game Development Company" />
        <meta
          name="description"
          content="We are expert web3 game development company, creating interactive gaming experiences with the best web3 game developers. We help you build your own web3 games that leverage smart contracts, NFTs, and decentralized protocols."
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/web3-game-development-company"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="game_head p2p game">
        <section className="">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-12 banner_con simple-video mb-sx-2 my-5">
                <h1 className="wow fadeInUp mt-5 text-center h1tag">
                  Web3 Game Development Company
                </h1>
                <p className="wow fadeInUp mt-4 text-center">
                  Block Intelligence is a leading web3 game development company
                  that creates innovative and immersive gaming solutions using
                  blockchain and artificial intelligence (AI) technologies. We
                  have a team of expert web3 game developers who can design and
                  develop games for various platforms.
                </p>
                <div className="d-block mx-auto mb-5">
                  <a href="#contact">
                    <img
                      src={require("../Images/gamebtn.webp")}
                      className="d-block mx-auto"
                      alt="p2p cryptocurrency exchange development company"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatgames">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent">
                  <h2 className="text-center">
                    What is <span>Web3 Game Development?</span>
                  </h2>
                  <p className="text-center">
                    Web3 game development is a process of creating decentralized
                    games that run on a blockchain network. Web3 games are
                    different from traditional games in many ways, such as
                  </p>

                  <p>
                    <span className="h5 my-2">Ownership: </span> Web3 games
                    allow players to own and control their in-game assets, such
                    as characters, items, skins, etc. These assets are
                    represented by non-fungible tokens (NFTs), which are unique
                    and scarce digital tokens that can be traded, sold, or used
                    across different games and platforms.
                  </p>
                  <p>
                    <span className="h5 my-2">Economy: </span> Web3 games enable
                    players to earn and spend crypto tokens within the game or
                    outside the game. These tokens can be used to buy, sell, or
                    rent in-game assets, access premium features, or participate
                    in governance and decision making.
                  </p>
                  <p>
                    <span className="h5 my-2">Community: </span> Web3 games
                    foster a strong and active community of players, developers,
                    and creators, who can interact, collaborate, and co-create
                    with each other.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="firstgames">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 mt-5">
                <h2>
                  Why Choose Block Intelligence for{" "}
                  <span>Web3 Game Development</span> ?
                </h2>
                <p>
                  At Block Intelligence, we are passionate about creating web3
                  games that are engaging, entertaining, and rewarding for the
                  players. We use the latest technologies and tools to create
                  web3 games that are
                </p>
                <p className="my-4">
                  <span className="h5 my-3">• AI-Powered:</span> We leverage AI
                  to enhance the gameplay, graphics, and user experience of your
                  web3 games. We use AI to analyze player behavior, preferences,
                  and feedback, and provide personalized and adaptive gaming
                  experiences. We also use AI to optimize the game performance,
                  security, and monetization.{" "}
                </p>
                <p>
                  <span className="h5 my-3">• Blockchain-Enabled:</span> We use
                  blockchain to add transparency, trust, and value to your web3
                  games. We use blockchain to store and verify game data,
                  assets, and transactions, and enable peer-to-peer interactions
                  and collaborations among players. We also use blockchain to
                  create and integrate crypto tokens, NFTs, and smart contracts
                  into your web3 games.
                </p>
              </div>
              <div className="col-lg-5 mt-5">
                <img
                  src={require("../Images/vikings.webp")}
                  className="vikingimages d-block mx-auto mt-5"
                  alt="p2p cryptocurrency exchange development company"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="gamesservices">
          <div className="container">
            <div className="feature-content pb-3">
              <h2 className="text-center">
                Benefits of Web3 <span>Game Development</span>
              </h2>
              <p className="text-center mt-4">
                Web3 game development can offer various benefits to both players
                and game developers,
              </p>
            </div>
            <div className="cex-cry-serv-card-row ">
              <div className="row ">
                <div className="col-lg-4 col-md-4">
                  <div className="cex-cry-serv-card gamess-1">
                    <div className="serv-card-content">
                      <p className="serv-card-content-title">
                        {" "}
                        Player Empowerment
                      </p>
                      <p className="serv-card-content-desc">
                        Web3 game development empowers players to have more
                        control and ownership over their gaming experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="cex-cry-serv-card gamess-2">
                    <div className="serv-card-content">
                      <p className="serv-card-content-title">Game Innovation</p>
                      <p className="serv-card-content-desc">
                        Web3 game development enables game developers to create
                        more innovative and immersive games that leverage the
                        unique features of blockchain and AI technologies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="cex-cry-serv-card gamess-3">
                    <div className="serv-card-content">
                      <p className="serv-card-content-title">Platform Growth</p>
                      <p className="serv-card-content-desc">
                        Web3 game development can help grow the web3 gaming
                        platform by attracting more players and game developers
                        to join and contribute to the platform.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gamesbenefits">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent ">
                  <h2 className="text-center">
                    Our Web3 <span>Game Development</span> Services
                  </h2>
                  <p className="text-center">
                    We offer a full range of web3 game development services,
                    from concept to launch, and beyond.
                  </p>
                </div>
                <div>
                  <GameDev />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gamesfeature">
          <div className="container">
            <div className="feature-content pb-3">
              <h2 className="text-center">
                Features of Web3 <span>Game Development</span>
              </h2>
              <p className="text-center mt-4">
                Web3 gaming platform development is a process of creating a
                decentralized platform that hosts and supports web3 games. A
                web3 gaming platform can offer various features
              </p>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 mb-4 col-md-4">
                <div className="">
                  <img
                    width="100%"
                    src={require("../Images/cards/K.webp")}
                    className=""
                    alt="Escrow System"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-4 col-md-4">
                <div className="">
                  <img
                    width="100%"
                    src={require("../Images/cards/A.webp")}
                    className=""
                    alt="Two Factor Authentication"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-4 col-md-4">
                <div className="">
                  <img
                    width="100%"
                    src={require("../Images/cards/Q.webp")}
                    className=""
                    alt="KYC and AML Verification"
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-4 col-md-4">
                <div className="">
                  <img
                    width="100%"
                    src={require("../Images/cards/2.webp")}
                    className=""
                    alt="Advanced Encryption"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gamesbenefits">
          <div className="container">
            <div className="feature-content pb-3">
              <h2 className="text-center">
                Hire Your Web3 <span>Game Developers</span>
              </h2>
              <p className="text-center text-white my-4">
                Block Intelligence offers you adroit web3 game developers to
                help you create innovative and immersive web3 gaming solutions
                using blockchain and AI technologies. We have the skills,
                experience, and passion to turn your web3 game ideas into
                reality. Contact us today and hire your web3 game developers.
              </p>
            </div>

            <div className="d-block mx-auto mb-5">
              <a href="https://wa.me/7738479381" target="_blank">
                <img
                  src={require("../Images/hirebtn.webp")}
                  className="d-block mx-auto"
                  alt="p2p cryptocurrency exchange development company"
                />
              </a>
            </div>
          </div>
        </section>

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <div className="row tech">
              <div className="col-lg-12 techstacks">
                <div className="slider">
                  <div className="slide-track">
                    <div className="slide">
                      <img
                        src={require("../Images/htmllogo.webp")}
                        className="bitcoin"
                        alt="html"
                      />
                      <p>Html</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/csslogo.webp")}
                        className="bitcoin"
                        alt="Crypto Bitcoin"
                      />
                      <p>Css</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/bootsrap.webp")}
                        className="bitcoin"
                        alt="Bootstrap"
                      />
                      <p>Bootstrap</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/angularlogo.webp")}
                        className="bitcoin"
                        alt="Angular Js"
                      />
                      <p>Angular</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/reactlogo.webp")}
                        className="bitcoin"
                        alt="React Js"
                      />
                      <p>React Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/nodejslogo.webp")}
                        className="bitcoin"
                        alt="Node Js"
                      />
                      <p>Node Js</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/net-logo.webp")}
                        className="bitcoin"
                        alt="Dot Net"
                      />
                      <p>Dot Net</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/phplogo.webp")}
                        className="bitcoin"
                        alt="Php"
                      />
                      <p>Php</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Python-Logo.webp")}
                        className="bitcoin"
                        alt="Python"
                      />
                      <p>Python</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/flutter-logo.webp")}
                        className="bitcoin"
                        alt="Flutter"
                      />
                      <p>Flutter</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Swift_logo.webp")}
                        className="bitcoin"
                        alt="Swift"
                      />
                      <p>Swift</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/iconic-logo.webp")}
                        className="bitcoin"
                        alt="Iconic"
                      />
                      <p>Iconic</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Mongodb-logo.webp")}
                        className="bitcoin"
                        alt="Mongo DB"
                      />
                      <p>Mongo DB</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/Elastic-logo.webp")}
                        className="bitcoin"
                        alt="ElasticSearch"
                      />
                      <p>ElasticSearch</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/PostgreSQL-logo.webp")}
                        className="bitcoin"
                        alt="PostgreSQL"
                      />
                      <p>PostgreSQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/mysql-logo.webp")}
                        className="bitcoin"
                        alt="My SQL"
                      />
                      <p>My SQL</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solidity-logo.webp")}
                        className="bitcoin"
                        alt="Solidity"
                      />
                      <p>Solidity</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/eth-logo.webp")}
                        className="bitcoin"
                        alt="Ethereum"
                      />
                      <p>Ethereum</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/hyper.webp")}
                        className="bitcoin"
                        alt="Hyperledger"
                      />
                      <p>Hyperledger</p>
                    </div>
                    <div className="slide">
                      <img
                        src={require("../Images/solana-logo.webp")}
                        className="bitcoin"
                        alt="Solona"
                      />
                      <p>Solona</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
